import React, { useRef, useEffect, useState } from 'react'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
} from 'reactstrap'
import endpoint from '../../config/config-api'
import Axios from 'axios'
import Swal from 'sweetalert2'
import Gallery from 'react-grid-gallery'
const axios = Axios.create({ withCredentials: true })
const FormHomaPage = (props) => {
  const [imageGallery, setImageGallery] = useState([])

  const [validate, setValidate] = useState(undefined)
  const [id] = useState()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    if (id) {
      const response = await axios.get(
        `${endpoint.apiTest}/v1.1/job/cms/panorama/${id}`,
      )
      if (response.status === 200) {
        const newData = response.data.data
        setImageGallery(newData)
      }
    } else {
      setImageGallery(imageGallery)
    }
  }

  const setValue = (name) => (event) => {
    if (props.imageGallery.length + event.target.files.length > 20) {
      alert('Limit 20 ภาพ')
      return
    }

    switch (name) {
      case 'imageGallery':
        let gallery =
          event.target.files[0] !== undefined && event.target.files[0]

        if (gallery) {
          ;(async (value = event.target.files) => {
            const check = await checkSizeImage(gallery)
            // if (!check) {
            //   Swal.fire({
            //     icon: 'error',
            //     title: 'ขนาดรูปภาพเกิน 1280 x 720',
            //     text: '  กรุณาเลือกรูปภาพใหม่',
            //   })
            // } else {
              if (
                ['image/jpg', 'image/jpeg', 'image/png'].includes(gallery.type)
              ) {
                setImageGallery({
                  imageGallery: [...value],
                })
                uploadImage([...value])
                setValidate({
                  ...validate,
                  imageGallery: '',
                })
              } else {
                setValidate({
                  ...validate,
                  imageGallery: 'กรุณาแนบไฟล์เป็น .jpg .jpeg .png เท่านั้น',
                })
              }
            // }
          })()
        }
        break

      default:
        break
    }
  }

  const checkSizeImage = (file) => {
    return new Promise((resolve) => {
      const url = URL.createObjectURL(file)
      let img = new Image()

      img.onload = function () {
        if (this.width < 1280 && this.height < 720) {
          resolve(true)
        } else {
          resolve(false)
        }
      }

      img.src = url
    })
  }

  const uploadImage = async (file) => {
    let formdata = new FormData()
    let gallerySrc = []

    file.map((item, index) => formdata.append('image_files', item))

    formdata.append('ref_id', Date.now())

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${endpoint.apiImageHost}/image/thairath/upload`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formdata,
    }

    axios
      .request(config)
      .then((response) => {
        response.data.data.items.map((item) => {
          // gallerySrc.push({
          //   image_id: item.image_id,
          //   image_name: item.image_name,
          //   url: item.url,
          //   mimetype: item.mimetype,
          //   width: item.width,
          //   height: item.height,
          // })
          handleSubmit({
            image_id: item.image_id,
            image_name: item.image_name,
            url: item.url,
            mimetype: item.mimetype,
            width: item.width,
            height: item.height,
          })
        })

        setImageGallery({
          imageGallery: gallerySrc,
        })
      })
      .catch((error) => {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'upload ไม่สำเร็จ',
        })
      })
  }

  const handleSubmit = async (gallerySrc) => {
    const imageGallery = {
      height: gallerySrc.height,
      width: gallerySrc.width,
      image_id: gallerySrc.image_id,
      image_name: gallerySrc.image_name,
      mimetype: gallerySrc.mimetype,
      url: gallerySrc.url,
    }

    if (id) {
      const result = await axios.put(
        `${endpoint.apiTest}/v1.1/job/cms/internship/update`,

        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      if (result.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูลสำเร็จ',
        }).then((result) => {
          if (result.isConfirmed) {
            props.history.push('/panorama')
          }
        })
      }
    } else {
      const result = await axios({
        method: 'post',
        url: `${endpoint.apiTest}/v1.1/job/cms/internship/create`,
        data: imageGallery,
      })

      if (result.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูลสำเร็จ',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'บันทึกข้อมูลไม่สำเร็จ',
        })
      }
    }
  }

  return (
    <Container>
      <Form noValidate onSubmit={handleSubmit}>
        <Col md={6}>
          <FormGroup>
            <Label for="exampleAddress">
              แกลลอรี่: ขนาด 1280 x 720 / นามสกุลไฟล์ .jpg, .png / Limit 20 ภาพ
              <br />
              {id && <Gallery images={imageGallery} />}
              {imageGallery.imageGallery
                ? imageGallery.imageGallery.map((item) => (
                    <>
                      {' '}
                      {item.name} <br />{' '}
                    </>
                  ))
                : `Example file input`}
            </Label>
            <Input
              type="file"
              multiple
              name="imageGallery"
              id="imageGallery"
              accept="image/jpg, image/jpeg, image/png"
              onChange={setValue('imageGallery')}
              style={{ cursor: 'pointer' }}
            />
            {validate && validate.imageGallery && (
              <Alert color="danger">{validate.imageGallery}</Alert>
            )}
          </FormGroup>
        </Col>

        <div></div>
        {/* <Row>
          <div className="btn">
            <Button onClick={() => handleSubmit()}>บันทึก</Button>
          </div>
          {console.log(props.history)}
          <div className="btn">
            <Button onClick={() => props.history.push('/EditHomePage')}>
              ย้อนกลับ
            </Button>
          </div>
        </Row> */}
      </Form>
    </Container>
  )
}

export default FormHomaPage
